<template>
  <div class="point">
    <div class="pointTitle">
      <img src="../../assets/img/head-img1.png"
           alt="">
    </div>
    <div class="pointText">
      <h1>Reasons for choosing to use points!</h1>
      <div class="textBox"
           v-for="(pointItem, pointindex) in pointsList"
           :key='pointindex'>
        <h4>{{pointItem.name}}</h4>
        <div class="sellerText">
          <p v-for="(item, pointi) in pointItem.text"
             :key='pointi'>
            <img :src="item.textImg"
                 alt="">
            {{item.textDes}}
          </p>
        </div>
      </div>
      <div>
        <h1>How does it work?</h1>
        <div class="textBox"
             v-for="(workItem, workIndex) in workList"
             :key='workIndex'>
          <h4>{{workItem.name}}</h4>
          <div class="sellerText workText">
            <p v-for="(item, i) in workItem.text"
               :key='i'>
              {{item.workName}}
            </p>
          </div>
        </div>
      </div>
      <p class="err">Note: The points function must be activated in the “My point” column of the background to obtain the right to use points.</p>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pointsList: [
        {
          name: 'For sellers:',
          text: [
            {
              textImg: require('../../assets/img/item-1.png'),
              textDes: 'Get more and higher quality reviews.'
            },
            {
              textImg: require('../../assets/img/item-2.png'),
              textDes: ' Save money.'
            },
            {
              textImg: require('../../assets/img/item-3.png'),
              textDes: 'Facilitate transactions between sellers and buyers.'
            },
            {
              textImg: require('../../assets/img/item-4.png'),
              textDes: 'Long-term cooperation with a large number of buyers.'
            },
            {
              textImg: require('../../assets/img/item-5.png'),
              textDes: 'Regardless of product category and value,you can send it in the form of points.'
            },
            {
              textImg: require('../../assets/img/item-6.png'),
              textDes: 'We are safe,efficient and convenient and there are no hidden charges.'
            }
          ]
        },
        {
          name: 'For buyers:',
          text: [
            {
              textImg: require('../../assets/img/item-8.png'),
              textDes: ' Earn money in the quickest and easiest way.'
            },
            {
              textImg: require('../../assets/img/item-4.png'),
              textDes: 'Long-term cooperation with a large number of sellers.'
            },
            {
              textImg: require('../../assets/img/item-7.png'),
              textDes: 'Get more free and discount products.'
            },
            {
              textImg: require('../../assets/img/item-6.png'),
              textDes: 'We are safe,efficient and convenient and there are no hidden charges.'
            },
            {
              textImg: require('../../assets/img/item-5.png'),
              textDes: 'Get more high-value products.'
            }
          ]
        }
      ],
      workList: [
        {
          name: 'Earning Points:',
          text: [
            { workName: '1.Register an account and complete personal information in Crediscounts;' },
            { workName: '2.Daily sign in;' },
            { workName: '3.Refer a friend(The invited user must register a Crediscounts account through your tracking link);' },
            { workName: '4.Buy freebies' },
            { workName: 'POINTS EXPIRATION POLICY:' },
            { workName: 'Members must remain active in Crediscounts Rewards Programs to retain Points they accumulate. If a Member has not logged into Crediscounts for 30 consecutive days, that Member will forfeit all accumulated Points. However, Crediscounts will send Members a reminder before the points expire.' }
          ]
        },
        {
          name: 'Buying Points:',
          text: [
            { workName: 'You can choose to recharge points by yourself, the points equivalent is: 1 $ = 100 points' },
            { workName: 'Note: Please check your account information carefully before recharging; points will not be refunded after recharging confirmation.' }
          ]
        },
        {
          name: 'Redeeming Points:',
          text: [
            { workName: 'Points> = 4500 can be redeemed for $ 37.50. If there are remaining points, the remaining points can be redeemed again when it reaches the threshold.' },
            { workName: 'For example, if a Member holds 12000 points, he can redeem for 100$, and the remaining points will be rolled to when he holds 4500 points and it can be redeemed.' },
            { workName: 'Note: The value of the US dollar may fluctuate with exchange rates.' }
          ]
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.point {
  padding: 15px 0;
  .pointTitle {
    background-color: white;
    margin: 15px;
  }
  .pointText {
    background-color: white;
    width: 80%;
    margin: auto;
    padding-bottom: 20px;
    h1 {
      font-size: 30px;
      line-height: 45px;
      padding: 15px 0;
      color: black;
      font-weight: 100;
    }
    .textBox {
      padding: 0 50px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 20px;
      h4 {
        display: inline-block;
        font-size: 24px;
        line-height: 56px;
        color: white;
        background-color: #ff9933;
        border-radius: 5px;
        padding: 0 50px;
        font-weight: 100;
        margin: 15px 0;
      }
      .sellerText {
        width: 100%;
        display: flex;
        justify-items: flex-start;
        flex-wrap: wrap;
        p {
          width: 50%;
          display: flex;
          text-align: left;
          justify-content: flex-start;
          align-items: center;
          line-height: 50px;
          font-size: 18px;
          img {
            display: block;
            width: 30px;
            height: auto;
            margin-right: 15px;
          }
        }
      }
      .workText {
        p {
          width: 100%;
        }
      }
    }
    .err {
      color: red;
      font-size: 16px;
      font-weight: bolder;
      text-align: left;
      padding-left: 50px;
    }
  }
}
</style>
